@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;700;900&family=Roboto:ital,wght@0,100;0,400;0,700;1,400&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: black;
}
html{
  scroll-behavior: smooth;
}

.white-text {
  color: #F2F2F2;
}
h1,h2,h3,h4,h5{
  font-family: 'Cinzel', serif;
  margin: 0;
  padding: 0;
  font-weight: bold !important;
}
.font-cinzel{
  font-family: 'Cinzel', serif;
  font-size: 20px;
  margin-bottom: 0px;
}
.title {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  max-width: 500px !important;
  border-bottom: solid 2px #818181;
  margin: auto;

}

.uppercase{
  text-transform: uppercase;
  font-family: 'Cinzel', serif;

}
a {
  text-decoration: none !important;
  color: initial ;
  transition: ease-in .2s ;
}
a:hover {
  color: #009BDE !important;
}
.max-width{
  max-width: 1300px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  
}
.max-width-m {
  max-width: 1200px;
}
@media (max-width: 768px) {
p{
  line-height: 1.2;
  margin-bottom: 5px !important;
}
h2 {
  font-size: 1.3em !important;
}
}